import React  from "react";
import styles from "../css/app.module.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Bounce } from "react-toastify";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Home,
  LogIn,
  Register,
  Dashboard,
  Dance,
  Quiz,
  Debate,
  Ritambhara,
  Anime,
  Comickaun,
  Filmsandphotography,
  MnM,
  HindiLiteraryEvents,
  Dramatics,
  Musicals,
  ELE,
  Finearts,
} from "../pages";
import Navbar from "./Navbar";
import { useAuth } from "../hooks";
import { Loader } from "../components";

const App = () => {
  const auth = useAuth();
  return auth.loading ? (
    <Loader />
  ) : (
    <div className={styles.app}>
      <ToastContainer transition={Bounce} />
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/login"
            element={
              !auth.user ? (
                <LogIn />
              ) : !auth.user.details ? (
                <Navigate replace to="/register" />
              ) : (
                <Navigate replace to="/dashboard" />
              )
            }
          ></Route>
          <Route
            path="/register"
            element={
              !auth.user ? (
                <Navigate replace to="/login" />
              ) : !auth.user.details ? (
                <Register />
              ) : (
                <Navigate replace to="/dashboard" />
              )
            }
          ></Route>
          <Route
            path="/dashboard"
            element={
              !auth.user ? (
                <Navigate replace to="/login" />
              ) : !auth.user.details ? (
                <Navigate replace to="/register" />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route path="/dance" element={<Dance />} />
          <Route path="/ele" element={<ELE />} />
          <Route path="/finearts" element={<Finearts />} />
          <Route path="/dramatics" element={<Dramatics />} />
          <Route path="/musicals" element={<Musicals />} />
          <Route path="/hle" element={<HindiLiteraryEvents />} />
          <Route path="/mnm" element={<MnM />} />
          <Route path="/comickaun" element={<Comickaun />} />
          <Route path="/fnp" element={<Filmsandphotography />} />
          <Route path="/debate" element={<Debate />} />
          <Route path="/ritambhara" element={<Ritambhara />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/anime" element={<Anime />} />
          {/* <Route path="/quiz" element={<Quiz />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>  
  );
};

export default App;



