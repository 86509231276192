import styles from "../css/dance.module.css";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {CompetitionCard} from "../components/index";
const Comickaun = () =>{
    return(
        <div className={styles.dance}>
            <h1 className={styles.pagetitle}>Comickaun</h1>
            <p className={styles.aboutdance}>
            "Comic Kaun?" marks a remarkable addition to the illustrious history of Antaragni, being the first-ever stand-up comic hunt featured in its 53rd edition. This exciting event promises to captivate audiences as participants from every corner of the country engage in a captivating battle of wit and timing. With the spotlight on these talented artists, the stage becomes a platform for them to unveil their comedic prowess before an enthusiastic and receptive audience.
            </p>
            <p className={styles.aboutdance}>As these aspiring comedians take the stage, their performances are set to be refined and honed, making it a valuable opportunity for personal growth and development. The experience gained during this event not only brings out the best in them but also plays a significant role in shaping their future in the entertainment industry. Supported by the passionate and dedicated team at DreamOns, these budding talents are provided with the encouragement and resources they need to thrive.
            </p>
            <p className={styles.aboutdance}>
            "Comic Kaun?" is not just a mere competition but an uplifting journey that fosters a sense of camaraderie and unity among participants and spectators alike. It showcases the power of humor in bringing people together, transcending barriers and fostering laughter and joy. By embracing the spirit of creativity and comedy, Antaragni reinforces its commitment to celebrating talent and nurturing the dreams of those who aspire to make it big in the entertainment world.
            </p>
            <p className={styles.aboutdance}>
            So, get ready to be enthralled by the wit and humor of these gifted artists, as they take center stage and make you burst into fits of laughter. Join us in this celebration of comedy, where we believe that laughter has the potential to shape careers and change lives. Let "Comic Kaun?" inspire you to embrace the power of humor, as we embark on this unforgettable journey of creativity and mirth.
            </p>
            <h2 className={styles.rulestitle}>Prelims Rules:</h2>
            <ol className={styles.ruleslist}>
                <li>All interested Comedians are required to register at the following link: https://roadtrips.antaragni.in/comickaun/register</li>
                <li>All participants are required to send video links of one of their previously recorded stand-up video performances. The content should be original. </li>
                <li>Reach the venue at least 30 minutes before the event starts.</li>
                <li>The winner(s) from each roadtrip will qualify for the finale to be held at IIT Kanpur.</li>
                <li>All further communication, including the announcement of results, will be via email with the contact details provided.</li>   
            </ol>

            <h2 className={styles.rulestitle}>General Event Rules:</h2>
            <ol className={styles.ruleslist}>
                <li>The event is open only for individual participants; both duets and group performances are not permitted.</li>
                <li>The decisions made by the judges are conclusive and obligatory.</li>
                <li>Performances must be delivered solely in Hindi or English; other languages will not be considered.</li>
                <li>Any content that could be deemed offensive or inappropriate within the college premises will not be tolerated, and the respective comedian will be disqualified,therefore get your content verified before the event.</li>
                <li>The organizers hold the right to modify any rules as they see fit.</li>
            </ol>

            <h2 className={styles.rulestitle}>Finale Rules:</h2>
            <ol className={styles.ruleslist}>
                <li>Each comedian will be allotted a time slot of 15 minutes for their performance. A gentle reminder will be provided when 10 minutes remain to conclude their act.</li>
                <li>The lineup for final event will be decided by lottery system</li>
                <li>It is imperative that all content presented during the performance is entirely original. In the event of any plagiarism being detected, the ultimate ruling on the matter will rest with the judges.</li>
            </ol>

            <h2 className={styles.rulestitle}>Judging Criteria:</h2>
            <h1 className={styles.ruleslist}>Humourous Content:40</h1>
            <h1 className={styles.ruleslist}>Content Delivery:20</h1>
            <h1 className={styles.ruleslist}>Stage Presence:20</h1>
            <h1 className={styles.ruleslist}>Audience Engagement:15</h1>
            <h1 className={styles.ruleslist}>Overall Impact:5</h1>
            <h1 className={styles.ruleslist}>TOTAL:100</h1>



            {/* <p className={styles.comp}>These are following competitions:</p>
                <ol className={styles.complist}>
                 <li>Jitterbug (western group dance)</li>
                 <li>Estampie (solo western dance)</li>
                 <li>Nrityangana (solo classical dance)</li>
                 <li>Tour de Force (street dance)</li>
                 <li>Roots (traditional group dance)</li>
                 <li>Duet (duet dance)</li>
                </ol>
                <h1 className={styles.comptitle}>Competitions</h1>
                <Accordion allowZeroExpanded className={styles.comprules}>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.rulebutton}>
                        {">"} Jitterbug
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Team Size: 5-35<br/>
                        Total no. of rounds: 3

                       <h1 className={styles.ruletitle}>Rules & Regulations:</h1>
                       <ol>
                        <li>Team size = Minimum: 5 and Maximum: 35</li>
                        <li>For elimination and first round of finals minimum 5 and maximum 30 are allowed on stage at a time.</li>
                        <li>For the second round of Finals maximum 25 are allowed on stage at a time.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 30 , Minimum: 5.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 24 , Minimum: No Restriction.</li>
                       </ol>
                       <h1 className={styles.ruletitle}>Elimination Round:</h1>
                       Teams will have to perform to a suitably edited part of the music from their main performance. The music which is used in the elimination round has to be a part of the first round of the finals.
                       <br/>
                       Team Limit:<br/>
                       Minimum: 2 minutes<br/>
                       Maximum: 4 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Finals:</h1><br/>
                       <h1 className={styles.ruletitle}>Round 1: Group Dance</h1>
                       Teams should present their complete performance (music piece duration). Costumes and props will be included in the judging criteria for this round.
                       Team Limit:<br/>
                       Minimum: 6 minutes<br/>
                       Maximum: 12 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Round 2: Dance Drama</h1>
                       <li>Teams will be given the movie to be depicted in this round randomly from a list of movies after the prelims. Lottery system will be followed for movie allotment. (Only Captain/ Leader is allowed to take part).</li>
                       <li>The teams have to depict the story of their respective movie without using the songs of the movie given to them.</li>
                       <li>Apart from the edited songs, usage of only the recorded voice of any team member (if needed) in the performance is permitted.</li>
                       <li>Usage of songs/dialogues from the given movie leads to direct disqualification of the team.</li>
                       <li>Also, it is strictly notified that this round is called Dance-Drama and thus the performance is expected to depict a dramatic situation with dance. Just a drama performance might not help your cause.</li>
                       Team Limit:<br/>
                       Minimum: 5 minutes<br/>
                       Maximum: 8 minutes<br/><br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.rulebutton}>
                        {">"} Estampie
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Team Size: 5-35<br/>
                        Total no. of rounds: 3

                       <h1 className={styles.ruletitle}>Rules & Regulations:</h1>
                       <ol>
                        <li>Team size = Minimum: 5 and Maximum: 35</li>
                        <li>For elimination and first round of finals minimum 5 and maximum 30 are allowed on stage at a time.</li>
                        <li>For the second round of Finals maximum 25 are allowed on stage at a time.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 30 , Minimum: 5.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 24 , Minimum: No Restriction.</li>
                       </ol>
                       <h1 className={styles.ruletitle}>Elimination Round:</h1>
                       Teams will have to perform to a suitably edited part of the music from their main performance. The music which is used in the elimination round has to be a part of the first round of the finals.
                       <br/>
                       Team Limit:<br/>
                       Minimum: 2 minutes<br/>
                       Maximum: 4 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Finals:</h1><br/>
                       <h1 className={styles.ruletitle}>Round 1: Group Dance</h1>
                       Teams should present their complete performance (music piece duration). Costumes and props will be included in the judging criteria for this round.
                       Team Limit:<br/>
                       Minimum: 6 minutes<br/>
                       Maximum: 12 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Round 2: Dance Drama</h1>
                       <li>Teams will be given the movie to be depicted in this round randomly from a list of movies after the prelims. Lottery system will be followed for movie allotment. (Only Captain/ Leader is allowed to take part).</li>
                       <li>The teams have to depict the story of their respective movie without using the songs of the movie given to them.</li>
                       <li>Apart from the edited songs, usage of only the recorded voice of any team member (if needed) in the performance is permitted.</li>
                       <li>Usage of songs/dialogues from the given movie leads to direct disqualification of the team.</li>
                       <li>Also, it is strictly notified that this round is called Dance-Drama and thus the performance is expected to depict a dramatic situation with dance. Just a drama performance might not help your cause.</li>
                       Team Limit:<br/>
                       Minimum: 5 minutes<br/>
                       Maximum: 8 minutes<br/><br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.rulebutton}>
                        {">"} Nrityangana
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Team Size: 5-35<br/>
                        Total no. of rounds: 3

                       <h1 className={styles.ruletitle}>Rules & Regulations:</h1>
                       <ol>
                        <li>Team size = Minimum: 5 and Maximum: 35</li>
                        <li>For elimination and first round of finals minimum 5 and maximum 30 are allowed on stage at a time.</li>
                        <li>For the second round of Finals maximum 25 are allowed on stage at a time.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 30 , Minimum: 5.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 24 , Minimum: No Restriction.</li>
                       </ol>
                       <h1 className={styles.ruletitle}>Elimination Round:</h1>
                       Teams will have to perform to a suitably edited part of the music from their main performance. The music which is used in the elimination round has to be a part of the first round of the finals.
                       <br/>
                       Team Limit:<br/>
                       Minimum: 2 minutes<br/>
                       Maximum: 4 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Finals:</h1><br/>
                       <h1 className={styles.ruletitle}>Round 1: Group Dance</h1>
                       Teams should present their complete performance (music piece duration). Costumes and props will be included in the judging criteria for this round.
                       Team Limit:<br/>
                       Minimum: 6 minutes<br/>
                       Maximum: 12 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Round 2: Dance Drama</h1>
                       <li>Teams will be given the movie to be depicted in this round randomly from a list of movies after the prelims. Lottery system will be followed for movie allotment. (Only Captain/ Leader is allowed to take part).</li>
                       <li>The teams have to depict the story of their respective movie without using the songs of the movie given to them.</li>
                       <li>Apart from the edited songs, usage of only the recorded voice of any team member (if needed) in the performance is permitted.</li>
                       <li>Usage of songs/dialogues from the given movie leads to direct disqualification of the team.</li>
                       <li>Also, it is strictly notified that this round is called Dance-Drama and thus the performance is expected to depict a dramatic situation with dance. Just a drama performance might not help your cause.</li>
                       Team Limit:<br/>
                       Minimum: 5 minutes<br/>
                       Maximum: 8 minutes<br/><br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.rulebutton}>
                        {">"} Roots
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Team Size: 5-35<br/>
                        Total no. of rounds: 3

                       <h1 className={styles.ruletitle}>Rules & Regulations:</h1>
                       <ol>
                        <li>Team size = Minimum: 5 and Maximum: 35</li>
                        <li>For elimination and first round of finals minimum 5 and maximum 30 are allowed on stage at a time.</li>
                        <li>For the second round of Finals maximum 25 are allowed on stage at a time.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 30 , Minimum: 5.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 24 , Minimum: No Restriction.</li>
                       </ol>
                       <h1 className={styles.ruletitle}>Elimination Round:</h1>
                       Teams will have to perform to a suitably edited part of the music from their main performance. The music which is used in the elimination round has to be a part of the first round of the finals.
                       <br/>
                       Team Limit:<br/>
                       Minimum: 2 minutes<br/>
                       Maximum: 4 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Finals:</h1><br/>
                       <h1 className={styles.ruletitle}>Round 1: Group Dance</h1>
                       Teams should present their complete performance (music piece duration). Costumes and props will be included in the judging criteria for this round.
                       Team Limit:<br/>
                       Minimum: 6 minutes<br/>
                       Maximum: 12 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Round 2: Dance Drama</h1>
                       <li>Teams will be given the movie to be depicted in this round randomly from a list of movies after the prelims. Lottery system will be followed for movie allotment. (Only Captain/ Leader is allowed to take part).</li>
                       <li>The teams have to depict the story of their respective movie without using the songs of the movie given to them.</li>
                       <li>Apart from the edited songs, usage of only the recorded voice of any team member (if needed) in the performance is permitted.</li>
                       <li>Usage of songs/dialogues from the given movie leads to direct disqualification of the team.</li>
                       <li>Also, it is strictly notified that this round is called Dance-Drama and thus the performance is expected to depict a dramatic situation with dance. Just a drama performance might not help your cause.</li>
                       Team Limit:<br/>
                       Minimum: 5 minutes<br/>
                       Maximum: 8 minutes<br/><br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.rulebutton}>
                        {">"} To De Force
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Team Size: 5-35<br/>
                        Total no. of rounds: 3

                       <h1 className={styles.ruletitle}>Rules & Regulations:</h1>
                       <ol>
                        <li>Team size = Minimum: 5 and Maximum: 35</li>
                        <li>For elimination and first round of finals minimum 5 and maximum 30 are allowed on stage at a time.</li>
                        <li>For the second round of Finals maximum 25 are allowed on stage at a time.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 30 , Minimum: 5.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 24 , Minimum: No Restriction.</li>
                       </ol>
                       <h1 className={styles.ruletitle}>Elimination Round:</h1>
                       Teams will have to perform to a suitably edited part of the music from their main performance. The music which is used in the elimination round has to be a part of the first round of the finals.
                       <br/>
                       Team Limit:<br/>
                       Minimum: 2 minutes<br/>
                       Maximum: 4 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Finals:</h1><br/>
                       <h1 className={styles.ruletitle}>Round 1: Group Dance</h1>
                       Teams should present their complete performance (music piece duration). Costumes and props will be included in the judging criteria for this round.
                       Team Limit:<br/>
                       Minimum: 6 minutes<br/>
                       Maximum: 12 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Round 2: Dance Drama</h1>
                       <li>Teams will be given the movie to be depicted in this round randomly from a list of movies after the prelims. Lottery system will be followed for movie allotment. (Only Captain/ Leader is allowed to take part).</li>
                       <li>The teams have to depict the story of their respective movie without using the songs of the movie given to them.</li>
                       <li>Apart from the edited songs, usage of only the recorded voice of any team member (if needed) in the performance is permitted.</li>
                       <li>Usage of songs/dialogues from the given movie leads to direct disqualification of the team.</li>
                       <li>Also, it is strictly notified that this round is called Dance-Drama and thus the performance is expected to depict a dramatic situation with dance. Just a drama performance might not help your cause.</li>
                       Team Limit:<br/>
                       Minimum: 5 minutes<br/>
                       Maximum: 8 minutes<br/><br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={styles.rulebutton}>
                        {">"} Duet
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Team Size: 5-35<br/>
                        Total no. of rounds: 3

                       <h1 className={styles.ruletitle}>Rules & Regulations:</h1>
                       <ol>
                        <li>Team size = Minimum: 5 and Maximum: 35</li>
                        <li>For elimination and first round of finals minimum 5 and maximum 30 are allowed on stage at a time.</li>
                        <li>For the second round of Finals maximum 25 are allowed on stage at a time.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 30 , Minimum: 5.</li>
                        <li>Stage Limit(for eliminations and Finals Round 1) = Maximum: 24 , Minimum: No Restriction.</li>
                       </ol>
                       <h1 className={styles.ruletitle}>Elimination Round:</h1>
                       Teams will have to perform to a suitably edited part of the music from their main performance. The music which is used in the elimination round has to be a part of the first round of the finals.
                       <br/>
                       Team Limit:<br/>
                       Minimum: 2 minutes<br/>
                       Maximum: 4 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Finals:</h1><br/>
                       <h1 className={styles.ruletitle}>Round 1: Group Dance</h1>
                       Teams should present their complete performance (music piece duration). Costumes and props will be included in the judging criteria for this round.
                       Team Limit:<br/>
                       Minimum: 6 minutes<br/>
                       Maximum: 12 minutes<br/><br/>
                       <h1 className={styles.ruletitle}>Round 2: Dance Drama</h1>
                       <li>Teams will be given the movie to be depicted in this round randomly from a list of movies after the prelims. Lottery system will be followed for movie allotment. (Only Captain/ Leader is allowed to take part).</li>
                       <li>The teams have to depict the story of their respective movie without using the songs of the movie given to them.</li>
                       <li>Apart from the edited songs, usage of only the recorded voice of any team member (if needed) in the performance is permitted.</li>
                       <li>Usage of songs/dialogues from the given movie leads to direct disqualification of the team.</li>
                       <li>Also, it is strictly notified that this round is called Dance-Drama and thus the performance is expected to depict a dramatic situation with dance. Just a drama performance might not help your cause.</li>
                       Team Limit:<br/>
                       Minimum: 5 minutes<br/>
                       Maximum: 8 minutes<br/><br/>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>
            <br/><br/> */}
                {/* <div className={styles.aftertitle}>
                    Judges:
                </div>
                <p className={styles.aftercontent}>To be announced</p>
                <br/><br/>
                <div className={styles.aftertitle}>
                    Sponsors:
                </div>
                <p className={styles.aftercontent}>To be announced</p>
                <br/><br/>
                <div className={styles.aftertitle}>
                    Incentives:
                </div>
                <p className={styles.aftercontent}>To be announced</p>
                <br/><br/> */}
                <div className={styles.aftertitle}>
                    Contacts:
                </div>
                <p className={styles.aftercontent}><ul>
                    <li>Ashutosh Bhardwaj
                        <br/>
                        9149100061
                    </li>
                    <li>Pranjal Gautam
                        <br/>
                        7073496505
                    </li>
                    </ul>
                    </p>
        </div>
    );
}

export default Comickaun;