import styles from "../css/dance.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";
export const Buttons = () => {
    const auth = useAuth();
    const location = useLocation();

    const navigate = useNavigate();
    return <div>

        {!auth.user ? (
            location.pathname === "/login" ? null : (
                <div className={styles.buttonContainer}>
                    <button
                        className={styles.button}
                        onClick={() => {
                            navigate("/login");
                        }}
                    >
                        𝐋𝐨𝐠𝐢𝐧
                    </button>
                </div>
            )
        ) : (
            <>

                <div className={styles.buttonContainer}>
                    <button className={styles.button} onClick={() => navigate("/")} >𝐇𝐨𝐦𝐞</button>
                    <button className={styles.button} onClick={() => {
                        auth.logout();
                    }}>𝐋𝐨𝐠𝐨𝐮𝐭</button>
                </div>

            </>
        )}

    </div>

}
