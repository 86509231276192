import React from "react";
import { FaEnvelope, FaFacebook, FaLinkedin } from "react-icons/fa";
import styles from "../css/organizerCard.module.css"; 

function OrganizerCard({ organizer }){
  const { Pic, Name, Post, Dept, Contact, Email, Facebook, LinkedIn, Instagram } = organizer;

  return (
    <div className={styles.organizercard}>
      <img src={Pic.content.url} alt={Name} className={styles.organizerimage} />
      <div className={styles.organizerdetails}>
        <div className={styles.h3}><h3>{Name.content}</h3></div>
        <h4>{Post.content}</h4>
        <h4>{Dept.content}</h4>
        <h4>{Contact.content}</h4>
        <div className={styles.organizersociallinks}>
          <a href={`mailto:${Email.content}`} className={styles.sociallink}><FaEnvelope /></a>
          <a href={Facebook.content} className={styles.sociallink} target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href={LinkedIn.content} className={styles.sociallink} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        </div>
      </div>
    </div>
  );
};

export default OrganizerCard;
