import React, { useState } from 'react';
import { IoClose, IoMenu } from "react-icons/io5";
import styles from '../css/dashboard.module.css';
import logo from "../assets/navbarlogo.png";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import { Team, ProfilePage, Competitions, Registrations } from './';
import PopUp from '../components/Popup/popup';

const Dashboard = () => {
  const auth = useAuth();
  const [activePage, setActivePage] = useState('Profile');
  const [menuOpen, setMenuOpen] = useState(false);

  const renderActivePage = () => {
    switch (activePage) {
      case 'Profile':
        return <ProfilePage />;
      case 'Team':
        return <Team />;
      case 'Registrations':
        return <Registrations />;
      case 'Competitions':
        return <Competitions />;
      default:
        return <ProfilePage />;
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    handleMenuToggle(); 
  };

  return (
    <>
      <PopUp />
      <nav className={`${styles.navbar} ${menuOpen ? styles.navbarOpen : ''}`}>
        <ul className={styles.navList}>
          <li><img src={logo} alt="Logo" className={styles.logo} /></li>
          <li
            className={`${styles.navItem} ${activePage === 'Home' ? styles.active : ''}`}
          >
            <Link to="/" className={styles.link} style={{ color: "black", textDecoration: "none" }}>
              Home
            </Link>
          </li>
          <li
            className={`${styles.navItem} ${activePage === 'Profile' ? styles.active : ''}`}
            onClick={() => handlePageChange('Profile')}
          >
            Profile
          </li>
          <li
            className={`${styles.navItem} ${activePage === 'Team' ? styles.active : ''}`}
            onClick={() => handlePageChange('Team')}
          >
            Team
          </li>
          <li
            className={`${styles.navItem} ${activePage === 'Registrations' ? styles.active : ''}`}
            onClick={() => handlePageChange('Registrations')}
          >
            Registrations
          </li>
          <li
            className={`${styles.navItem} ${activePage === 'Competitions' ? styles.active : ''}`}
            onClick={() => handlePageChange('Competitions')}
          >
            Competitions
          </li>
          <li className={`${styles.logout} ${styles.navItem}`} onClick={() => auth.logout()}>
          𝐋𝐨𝐠𝐨𝐮𝐭
          </li>
        </ul>
        <div className={styles.navClose} onClick={handleMenuToggle}>
          <IoClose />
        </div>
      </nav>
      <div className={styles.navToggle} onClick={handleMenuToggle}>
        <IoMenu />
      </div>
      <div className={styles.dashboard}>
        <div className={styles.content}>
          {renderActivePage()}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
