import styles from "../css/dance.module.css";
export const Guidelines = () => {
    return <div className={styles.afterguidelines}>
  
    <ul>
      <li>Teams must follow the Time Limits and strictly stick to the allowed styles. Teams shall be penalized for not doing so.</li>
      <li>Any kind of fluid, flame, powders, glitters, heavy or sharp objects or any material which has the possibility of tampering/damaging the stage is STRICTLY not allowed.</li>
      <li>All the slotting will be done by lottery system.</li>
      <li>The decision of the judges shall be final and binding.</li>
      <li>Obscenity of any kind is not allowed and will lead to immediate disqualification.</li>
      <li>Pre-recorded music should be brought in a pen drive in .mp3 format only.</li>
      <li>Scoresheet of each judge will be provided to the participating teams.</li>
      <li>"Decision of the judges will be final and binding".</li>
      <li>The organizing team reserves the right to change or modify the rules.</li>
    </ul>
  </div>
}