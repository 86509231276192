import anime from "./Events/anime.jpg"
import dance from './Events/dance_s.jpeg'
import debate from './Events/Debate.png'
import dramatics from './Events/dramatics_s.jpeg'
import els from './Events/ELS.png'
import  fnc from './Events/Photography.png'
import finearts from './Events/fine_arts_final.jpg'
import hindilit from './Events/HLE.png'
import mnm from './Events/mnm.jpeg'
import music from './Events/music_s.jpeg'
import quiz from './Events/quiz1.png'
import ritambhara from './Events/ritambhara1.JPG'

const images = [
    anime, 
    dance,
    debate,
    dramatics,
    els,
    fnc,
    finearts,
    hindilit,
    mnm,
    music,
    quiz,
    ritambhara
];

export const events = [
  {
    name: "Anime Convention",
    desc: "",
    link: '/anime'
  },
  {
    name: "Dance",
    desc: "",
    link: '/dance'
  },
  {
    name: "Debate",
    desc: "",
    link: '/debate'
  },
  {
    name: "Dramatics",
    desc: "",
    link: '/dramatics'
  },
  {
    name: "English Literary Events",
    desc: "",
    link: "/ele"
  },
  {
    name: "Films & Photography",
    desc: "",
    link: 'fnp'
  },
  {
    name: "Fine Arts",
    desc: "",
    link: '/finearts'
  },
  {
    name: "Hindi Literary Events",
    desc: "",
    link: '/hle'
  },
  {
    name: "Mr & Ms Antaragni",
    desc: "",
    link: '/mnm'
  },
  {
    name: "Music",
    desc: "",
    link: '/musicals'
  },
  {
    name: "Quiz",
    desc: "",
    link: '/quiz'
  },
  {
    name: "Ritambhara",
    desc: "",
    link: 'ritambhara'
  },
]

export default images;