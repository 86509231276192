import styles from './css/style.module.css'
import popup from "../../assets/popup.png"
import { useEffect, useRef } from 'react'

const PopUp = () => {
    const ref = useRef()

    const getApp = () => {
        window.open("https://play.google.com/store/apps/details?id=antaragni.flame", "_blank")
    }
    const joinGroup = () => {
        window.open("https://whatsapp.com/channel/0029Vak8LmD9mrGWHTsPIR3r", "_blank")    
    }
    const handleClose= () => {
        if(ref.current){
            ref.current.className = styles.hidden
            sessionStorage.setItem("popup", "true")
        }
    }

    useEffect(() => {
        const popUpShown = sessionStorage.getItem("popup")
        if(popUpShown === "null")
            sessionStorage.setItem("popup", false)
        else if(popUpShown === "true"){
            handleClose()
        }

    }, [])

    return (
        <div className={styles.container} ref={ref}>
            <div className={styles.imgContainer}>
                <div className={styles.close} onClick={handleClose}>X</div>
                <div className={styles.imgText}>
                    <h1>Reach us here!!</h1>
                    <div className={styles.linkContainer}>
                        <div className={styles.text}>Get the App : </div>
                        <div className={styles.btn} onClick={getApp}>Download</div>
                    </div>
                    <div className={styles.linkContainer}>
                        <div className={styles.text}>Join Us on WhatsApp : </div>
                        <div className={styles.JoinBtn} onClick={joinGroup}>Channel Link</div>
                    </div>
                </div>
                <img src={popup} alt="links" />
            </div>
        </div>
    )
}

export default PopUp