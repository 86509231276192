import styles from "../css/registrations.module.css";
import btnStyles from "../css/button.module.css";
import { useAuth } from "../hooks";
import { useState } from "react";
// import { updateData } from "../config/firebaseFirestore";
import Popup from "reactjs-popup";
import wingsl from "../assets/dashboard-components/wings-left.png";
import wingsr from "../assets/dashboard-components/wings-right.png";
import "reactjs-popup/dist/index.css";
import { useEffect } from "react";

const Registrations = () => {
  const auth = useAuth();
  const comps = auth.registeredCompetitions;

  useEffect(() => {
    const originalHtmlStyles = document.documentElement.style.cssText;
    const originalBodyStyles = document.body.style.cssText;

    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";
    document.documentElement.style.height = "100%";
    document.documentElement.style.overflow = "hidden";

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.cssText = originalHtmlStyles;
      document.body.style.cssText = originalBodyStyles;
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <img src={wingsl} className={styles.wings} alt="Wings"></img>
        <h1 className={styles.heading}>REGISTRATIONS</h1>
        <img src={wingsr} className={styles.wings} alt="Wings"></img>

      </div>
      <div className={styles.regs}>
        <div className={styles.wrapper}>
          <div className={styles.corner}></div>
          <div className={styles.borderTop}></div>
          <div className={styles.borderBottom}></div>
          <div className={styles.borderLeft}></div>
          <div className={styles.borderRight}></div>
          {!auth.loading && (
            <table className={styles.table}>
              <thead>
                <tr className={styles.entry}>
                  {/* <th>Event Name</th> */}
                  <th>Competition</th>
                  {/* <th>Status</th> */}
                  <th>Submission</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
              {comps &&
                comps.map((reg) => {
                  return <Row reg={reg} />;
                })}
              </tbody>
            </table>
          )}
        </div>
      </div></div>
  );
};

const Row = ({ reg }) => {
  const auth = useAuth();
  const contentStyle = {
    background: "rgba(255, 255, 255, 0.12)",
    borderRadius: "16px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(4.3px)",
    border: "1px solid rgba(255, 255, 255, 1)",
  };
  const [perLink, setPerLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  return (
    <tr className={styles.entry}>
      {/* <td>{reg.data.event}</td>
      <td className={styles.competition}>{reg.data.competition}</td>
      <td>{reg.data.status === undefined ? "In Review" : reg.data.status}</td> */}
      <td>{reg.competition}</td>
      <td>
        {reg.link === undefined || reg.link === "" ? (
          <Popup
            modal={true}
            trigger={
              <button className={styles.btn}>Add Performance Link</button>
            }
            {...{ contentStyle }}
            position="center center"
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
              setPerLink("");
            }}
            onOpen={() => {
              setIsOpen(true);
            }}
          >
            <div className={styles.submitModal}>
              <div className={styles.textInput}>
                <label className={styles.label} htmlFor="perLink">
                  Performance Link*
                </label>
                <input
                  className={styles.input}
                  id="perLink"
                  type="url"
                  required
                  value={perLink}
                  placeholder="Enter Performance Link"
                  onChange={(e) => {
                    setPerLink(e.target.value);
                  }}
                />
              </div>
              <button
                className={btnStyles.btn}
                onClick={async (e) => {
                  if (perLink != null && perLink != "") {
                    auth.addLink({
                      competition: reg.competition,
                      link: perLink,
                    });

                    // events.getAllRegistrations();
                    setPerLink("");
                    setIsOpen(false);
                  }
                }}
              >
                Submit
              </button>
            </div>
          </Popup>
        ) : (
          <button className={styles.btn} onClick={() => { window.location.href = reg.link; }}>View Submission</button>
        )}
      </td>
    </tr>
  );
};

export default Registrations;
