import images from '../../assets'
import PropTypes from 'prop-types'

export const DotButton = (props) => {
  const { onClick, className, selected, index} = props
  return (
    <div className={`${className}`} onClick={onClick} >
      <img src={images[index]} alt="" />
    </div>
  )
}

DotButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    selected: PropTypes.bool,
    index: PropTypes.number
}
