import React from "react";
import styles from "../css/dance.module.css";
import { getSingleDoc } from "../config/firebaseFirestore";
import { useEffect,useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {CompetitionCard,Loader} from "../components/index";
import { Guidelines } from "../components/Guidelines";
import { Buttons } from "../components/Buttons";
import ReactMarkDown from "react-markdown";

import wingsl from "../assets/dashboard-components/wings-left.png";
import wingsr from "../assets/dashboard-components/wings-right.png";

const Quiz = () => {

    const[heading, setHeading] = useState("");
  const[overview, setOverview] =useState("");
  const[comps, setComps]= useState([]);
  const[contacts,setContacts]=useState("");
  const[loading,setLoading]=useState(true);
  
  const fetchQuiz = async ()=>{
    setLoading(true);
    try{
      let data= await getSingleDoc("WebContentsNew","events_quiz");
      if (data!== null){
        let headingArray=data.data.filter((e)=>{return e.flag.content ==="heading"});
        let compsArray=data.data.filter((e)=>{return e.flag.content ==="comp"});
        let overviewArray=data.data.filter((e)=>{return e.flag.content ==="overview"});
        let contactsArray=data.data.filter((e)=>{return e.flag.content ==="contacts"});
        setHeading(headingArray[0].title.content);
        setOverview(overviewArray[0].desc.content);
        setComps(compsArray);
        setContacts(contactsArray[0].desc.content);
        setLoading(false);
        // console.log(data)
        // console.log(headingArray);
      }
    }catch(e){
      console.log("Error in debate",e);
    }
  }
  
  useEffect(()=>{
    fetchQuiz();
  },[]);
  
    return (
      <div className={styles.dance}>
        <Buttons/>
        <div className={styles.pagetitleContainer}>
          <img src={wingsl} className={styles.wings} alt="Wings left" />
        <h1 className={styles.pagetitle}>{heading}</h1>
        <img src={wingsr} className={styles.wings} alt="Wings right" />
        </div>
        <div className={styles.aboutdance}>
          <ReactMarkDown>
          {overview.substring(0)}
          </ReactMarkDown>
        </div>
        <h1 className={styles.guidelines} >Guidelines</h1>
        <Guidelines />


        <h1 className={styles.comptitle}>Competitions</h1>

      <Accordion allowZeroExpanded className={styles.comprules}>
        {comps.map((e)=>{
          return <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton className={styles.rulebutton}>
              {">"}{e.title.content}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.accordionContent}>
              <ReactMarkDown>
              {e.desc.content.substring(0)}
              </ReactMarkDown>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        })}
        </Accordion>
      <br />
      <br />

      <div className={styles.aftertitle}>Contacts</div>
      <p>
      <div className={styles.aboutContacts}>
        <ReactMarkDown>
        {contacts.substring(0)}
        </ReactMarkDown>
        </div></p>
      </div>
  );
};

export default Quiz;