import style from "../css/eventsNav.module.css"

import { Menu } from "lucide-react"

const EventsNav = () => {
    return (
            <div className={style.container}>
                <div className={style['card-left']}></div>
                <div className={style['card-right']}></div>
                <div className={style.menu}>
                    <Menu color="white" />
                </div>
                <div className={style.buttonContainer}>
                    <div className={style.button}>𝐀𝐥𝐥</div>
                    <div className={style.button}>𝐄𝐯𝐞𝐧𝐭𝐬</div>
                </div>
                <div className={style.menu}>
                    <Menu color="white"/>
                </div>
            </div>
    )
}

export default EventsNav