import styles from "../css/team.module.css";
import { useState, useEffect } from "react";
import { queryData } from "../config/firebaseFirestore";
import { useAuth } from "../hooks";
import wingsl from "../assets/dashboard-components/wings-left.png";
import wingsr from "../assets/dashboard-components/wings-right.png";

const Team = () => {
  const auth = useAuth();
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const getTeamDetails = async () => {
    setLoading(true);
    try {
      let data = await queryData(
        "eventsUsers2024",
        "teamId",
        auth.user.details.teamId
      );
      setTeam(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTeamDetails();
    const originalHtmlStyles = document.documentElement.style.cssText;
    const originalBodyStyles = document.body.style.cssText;

    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";
    document.documentElement.style.height = "100%";
    document.documentElement.style.overflow = "hidden";

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.cssText = originalHtmlStyles;
      document.body.style.cssText = originalBodyStyles;
    };
  }, []);

  return (
    <div className={styles.teamContainer}>
      <div className={styles.wingsContainer}>
        <img src={wingsl} className={styles.wings} alt="Left Wing" />
        <h1
          className={styles.teamtitle}>
          TEAM
        </h1>
        <img src={wingsr} className={styles.wings} alt="Right Wing" />
      </div>
      <div className={styles.team}>
        <div className={styles.wrapper}>
          <div className={styles.borderTop}></div>
          <div className={styles.borderBottom}></div>
          <div className={styles.borderLeft}></div>
          <div className={styles.borderRight}></div>
          <div className={styles.corner}></div>
          <table className={styles.table}>
            <thead>
              <tr className={styles.entry}>
                <th>Antaragni Id</th>
                <th>Team Name</th>
                <th>Name</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {team.map((user) => {
                return (
                  <tr className={styles.entry}>
                    <td>{user.data.id}</td>
                    <td>{user.data.teamName}</td>
                    <td>{user.data.name}</td>
                    <td>{user.data.phone}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Team;
