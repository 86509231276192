import { useEffect } from "react";
import styles from "../css/competition.module.css";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import wingsl from "../assets/dashboard-components/wings-left.png";
import wingsr from "../assets/dashboard-components/wings-right.png";

const Competitions = () => {
  const auth = useAuth();

  useEffect(() => {
    const originalHtmlStyles = document.documentElement.style.cssText;
    const originalBodyStyles = document.body.style.cssText;

    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";
    document.documentElement.style.height = "100%";
    document.documentElement.style.overflow = "hidden";

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.cssText = originalHtmlStyles;
      document.body.style.cssText = originalBodyStyles;
    };
  }, []);

  return (
    !auth.loading && (
      <div className={styles.container}>
        <div className={styles.row}>
          <img src={wingsl} className={styles.wings} alt="Wings" />
          <h1 className={styles.heading}>COMPETITIONS</h1>
          <img src={wingsr} className={styles.wings} alt="Wings" />
        </div>
        <div className={styles.comp}>
          <div className={styles.wrapper}>
            <div className={styles.corner}></div>
            <div className={styles.borderTop}></div>
            <div className={styles.borderBottom}></div>
            <div className={styles.borderLeft}></div>
            <div className={styles.borderRight}></div>
            <table className={styles.table}>
              <thead>
                <tr className={styles.entry}>
                  <th>Event Name</th>
                  <th>Rulebook</th>
                  <th>Competition</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Participate</th>
                </tr>
              </thead>
              <tbody>
                {auth.unregisteredCompetitions.map((event) => {
                  return (
                    <tr className={styles.events}>
                      <td className={styles.afont}>{event.event}</td>
                      <td>
                        <Link to={event.link}>
                          <button className={styles.lbtn}>View Rulebook</button>
                        </Link>
                      </td>
                      <td className={styles.ifont}>{event.comp}</td>
                      <td className={styles.ifont}>{event.desc}</td>
                      <td className={styles.ifont}>{event.type}</td>
                      <td className={styles.ifont}>
                        <button
                          className={styles.rbtn}
                          onClick={(e) => {
                            auth.registerCompetition(event.comp);
                          }}
                        >
                          Register
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
};

export default Competitions;
