import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ShowToast = (title, time = 900) => {
  return toast(title, {
    position: "top-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: true,
    theme: "dark",
  });
};

export default ShowToast;
