import { useEffect, useRef, useState } from "react";

const Waves = (props) => {
    const [path, setPath] = useState('')
    const ref = useRef()
    let lastUpdate = 0
    let step = 0
    let elapsed = 0
    let frameId

    useEffect(() => {
        draw()
        return () => {
            window.cancelAnimationFrame(frameId)
        }
    })

    const calculateWavePoints = () => {
        const points = []
        for(let i = 0; i < Math.max(props.options.points, 1); i++){
            const scale = 50
            const x = i / props.options.points * width()
            const seed = (step + ( i + i % props.options.points)) * props.options.speed * scale
            const height = Math.sin(seed / scale) * props.options.amplitude
            const y = Math.sin(seed / scale) * height + props.options.height
            points.push({x, y})
        }
        return points
    }

    const buildPath = (points) => {
        let svg = `M ${points[0].x} ${points[0].y}`
        const initial = {
            x: (points[1].x - points[0].x) / 2,
            y: (points[1].y - points[0].y)  + points[0].y + (points[1].y - points[0].y)
        }
        const cubic = (a, b) => ` C ${a.x} ${a.y} ${a.x} ${a.y} ${b.x} ${b.y}`
        svg += cubic(initial, points[1])
        let point =  initial
        for(let i = 1; i < points.length - 1; i++){
            point = {
                x: (points[i].x - point.x) + points[i].x,
                y: (points[i].y - point.y) + points[i].y
            }
            svg += cubic(point, points[i + 1])
        }
        svg += ` L ${width()} ${height()}`
        svg += ` L 0 ${height()} Z`
        return svg 
    }

    const width = () => ref.current.offsetWidth 
    const height = () => ref.current.offsetHeight

    const redraw = () => {
        setPath(buildPath(calculateWavePoints()))
    }

    const draw = () => {
        const now = new window.Date()
        elapsed += (now - lastUpdate)
        lastUpdate = now
        const scale = 1000
        step = elapsed * Math.PI / scale
        redraw()
        frameId = window.requestAnimationFrame(draw)
    }

    return (
        <div className={props.className} ref={ref}>
            <svg width="200vw" height="80%" version="1.1" xmlns="http://w3.org/2000/svg">
                {props.children}
                <path d={path} fill={props.fill}/>
            </svg>
        </div>
    )
}

export default Waves