import styles from "../css/dance.module.css";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { CompetitionCard } from "../components/index";
import { getSingleDoc } from "../config/firebaseFirestore";
import React, { useState, useEffect } from "react";
import { Loader } from "../components/index";
import { Buttons } from "../components/Buttons";
import ReactMarkdown from 'react-markdown';

import wingsl from "../assets/dashboard-components/wings-left.png";
import wingsr from "../assets/dashboard-components/wings-right.png";

const MnM = () => {
    const [heading, setHeading] = useState("");
    const [overview, setOverview] = useState("");
    const [contacts, setContacts] = useState("");
    const [loading, setLoading] = useState(true);





    const fetchMnM = async () => {
        setLoading(true);
        try {
            let data = await getSingleDoc("WebContentsNew", "events_MnM");
            if (data !== null) {
                let headingArray = data.data.filter((e) => { return e.flag.content == "heading" });
                let overviewArray = data.data.filter((e) => { return e.flag.content == "overview" });
                let contactsArray = data.data.filter((e) => { return e.flag.content == "contacts" });
                setHeading(headingArray[0].title.content);
                setContacts(contactsArray[0].desc.content);
                setOverview(overviewArray[0].desc.content);
                setLoading(false);

            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchMnM();
    }, []);
    return (
        <div className={styles.dance}>
            <Buttons />
            <div className={styles.pagetitleContainer}>
                <img src={wingsl} className={styles.wings} alt="Wings left" />
                <h1 className={styles.pagetitle}>{heading}</h1>
                <img src={wingsr} className={styles.wings} alt="Wings right" />
            </div>
            <p className={styles.aboutdance}>
                <ReactMarkdown>{overview}</ReactMarkdown>
            </p>
            <div className={styles.aftertitle}>
                Contacts:
            </div>
            <div div className={styles.aboutContacts}>
                <ReactMarkdown>{contacts.substring(0)}</ReactMarkdown>
            </div>
        </div>
    );
}

export default MnM;