import { useCallback, useEffect, useRef, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './CarouselArrowButtons'
import { DotButton } from './CarouselDotButton'
import PropTypes from 'prop-types'
import './css/styles.css'
import images from '../../assets'
import { events } from '../../assets'
import { useNavigate } from 'react-router-dom'

const TWEEN_FACTOR_BASE = 0.52

const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max)

const Carousel = (props) => {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [dotsRef, emblaDotsApi] = useEmblaCarousel({loop: true})
  const tweenFactor = useRef(0)
  const tweenNodes = useRef([])
  const navigate = useNavigate()

    const onDotsClick = useCallback((index) => {
        if(!emblaApi || !emblaDotsApi) return
        emblaApi.scrollTo(index)
    }, [emblaApi, emblaDotsApi])

    const onSelect = useCallback(() => {
        if(!emblaApi || !emblaDotsApi) return
        setSelectedIndex(emblaApi.selectedScrollSnap())
        emblaDotsApi.scrollTo(emblaApi.selectedScrollSnap())
    }, [emblaApi, emblaDotsApi, setSelectedIndex])

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const setTweenNodes = useCallback((emblaApi) => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector('.embla__slide__number')
    })
  }, [])

  const setTweenFactor = useCallback((emblaApi) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length / 3
  }, [])

  const tweenScale = useCallback((emblaApi, eventName) => {
    const engine = emblaApi.internalEngine()
    const scrollProgress = emblaApi.scrollProgress()
    const slidesInView = emblaApi.slidesInView()
    const isScrollEvent = eventName === 'scroll'

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress
      const slidesInSnap = engine.slideRegistry[snapIndex]

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target()

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target)

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress)
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress)
              }
            }
          })
        }
        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
        const scale = numberWithinRange(tweenValue, 0, 1)
        const tweenNode = tweenNodes.current[slideIndex]
        tweenNode.style.transform = `scale(${scale})`
    //     if(scale < 0.9){
    //         if(scale >  0.75){
    //         tweenNode.style.transform = `scalex(0.6)`
    //         if(diffToTarget < 0){
    //             tweenNode.style.translate = `90px 0px`        
    //         }else{
    //             tweenNode.style.translate = `-90px 0px`
    //         }
    //     }else{
    //         tweenNode.style.transform = `scale(0.6, 0.8)`
    //         if(diffToTarget < 0){
    //             tweenNode.style.translate = `260px 0px`        
    //         }else{
    //             tweenNode.style.translate = `-260px 0px`
    //         }
    //     }
    // }else{
    //     tweenNode.style.transform = "scale(1, 1)"
    //     tweenNode.style.translate = "0px 0px"
    // }
      })
    })
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    setTweenNodes(emblaApi)
    setTweenFactor(emblaApi)
    tweenScale(emblaApi)
    onSelect()

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenScale)
      .on('reInit', onSelect)
      .on('scroll', tweenScale)
      .on('slideFocus', tweenScale)
      .on('select', onSelect)
  }, [emblaApi, tweenScale, onSelect])

  const handleClick = (index) => {
    navigate(events[index].link)
  }

  return (
    <div className="embla"> 
      <div className="embla__viewport" ref={emblaRef} >
        <div className="embla__container">
          {slides.map((index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number" style={{backgroundImage: `url(${images[index]})`}} onClick={() => {
                if(selectedIndex === index) handleClick(index)
              }}>
                {
                  selectedIndex === index &&
                  <>
                    <div className="event-details">{events[index].desc}</div>
                    <div className="events-name">{events[index].name}</div>
                  </>
                }
              </div>
            </div>
          ))}
        </div>
        <div style={{position: "absolute", right: "0.25rem", top: "50%", transform: 'translate(0, -50%)'}}>
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
        <div style={{position: "absolute", left: "0.25rem", top: "50%", transform: 'translate(0, -50%)'}}>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__dots">
            <div className="embla-dots__viewport" ref={dotsRef}> 
                <div className="embla-dots__container">
                {slides.map((_, index) => (
                    <DotButton
                    key={index}
                    onClick={() => onDotsClick(index)}
                    selected = {index === selectedIndex}
                    index = {index}
                    className={'embla__dot'.concat(
                        index === selectedIndex ? ' embla__dot--selected' : ''
                    )}
                    />
                ))}
                </div>
            </div>          
        </div>
      </div>
    </div>
  )
}

export default Carousel


Carousel.propTypes = {
    slides: PropTypes.array,
    options: PropTypes.object,
}
