// import {  ColorRing  } from 'react-loader-spinner';
// import styles from "../css/home.module.css";

import '../css/loader.css'
// import text from '../assets/Centre_Text.png';
import center_text from '../assets/Centre_Text.png';
const Loader = () => {
  return (
    // <div
    //   style={{
    //     width: "100vw",
    //     height: "100vh",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     backgroundImage: `url('C:\Users\kumar\Desktop\events\EventsPortal24\src\assets\dashboard-components\dashboardbg.png')`,
    //     backgroundSize: "cover",
    //     backgroundRepeat: "no-repeat",
    //   }}
    // >
    //   <MutatingDots
    //     height="100"
    //     width="100"
    //     color="maroon"
    //     secondaryColor="maroon"
    //     radius="15px"
    //     ariaLabel="mutating-dots-loading"
    //     wrapperStyle={{}}
    //     wrapperClass=""
    //     visible={true}
    //   />
    // </div>
    <>
        <div id="loader-wrapper">
      <div className="loaderimg" >
          <img src={center_text} alt="Loading"  className='images'/>
      </div>
    <div className="loader">

    </div>
    <div className="loader-text"><strong> 
    <div id="load">
  <div>G</div>
  <div>N</div>
  <div>I</div>
  <div>D</div>
  <div>A</div>
  <div>O</div>
  <div>L</div>
</div>
</strong></div>
</div>

    </>
  );
};

export default Loader;
