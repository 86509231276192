import { AuthContext } from "../providers";
import { useState, useEffect, useContext } from "react";
import { ShowToast } from "../components";
import eventsData from "../data/events.json";
import { firebaseGetUser, firebaseLogout } from "../config/firebaseAuth";
import { setData, updateData } from "../config/firebaseFirestore";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useProvideAuth = () => {
  const data = eventsData.data;
  const [user, setUser] = useState(null);
  const [registeredCompetitions, setRegisteredCompetitions] = useState([]);
  const [unregisteredCompetitions, setUnregisteredCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateUser = (user) => {
    try {
      setUser(user);
      setRegisteredCompetitions(user.details.userComps || []);
      getUnregisteredCompetitions(user.details.userComps || []);
    } catch (e) {
      console.log(e);
    }
  };

  const getUnregisteredCompetitions = (regs) => {
    try {
      let sortedData = [...data];
      for (let i = 0; i < regs.length; i++) {
        let reg = regs[i];
        sortedData = sortedData.filter((e) => reg.competition !== e.comp);
      }
      setUnregisteredCompetitions(sortedData);
    } catch (e) {
      console.log(e);
    }
  };

  const getUser = async () => {
    setLoading(true);
    firebaseGetUser(updateUser, setLoading);
  };
  useEffect(() => {
    getUser();
  }, []);

  const register = async (data) => {
    try {
      await setData("eventsUsers2024", user.user.uid, data);
      ShowToast("Registered Successfully");
      getUser();
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    await firebaseLogout();
    setUser(null);
    ShowToast("Logged out successfully");
  };

  const registerCompetition = async (comp) => {
    try {
      let newComps = [...registeredCompetitions, { competition: comp }];
      let onlyComps = [];
      for (let comp of newComps) {
        onlyComps.push(comp.competition);
      }
      await updateData("eventsUsers2024", user.user.uid, {
        userComps: newComps,
        competitions: onlyComps,
      });
      let newUnRegComps = unregisteredCompetitions.filter(
        (e) => e.comp !== comp
      );
      setRegisteredCompetitions(newComps);
      setUnregisteredCompetitions(newUnRegComps);
    } catch (e) {
      console.log(e);
    }
  };

  const addLink = async (comp) => {
    try {
      let indexOfComp = registeredCompetitions.findIndex(
        (e) => e.competition === comp.competition
      );
      let newComps = [...registeredCompetitions];
      newComps[indexOfComp] = comp;
      await updateData("eventsUsers2024", user.user.uid, {
        userComps: newComps,
      });
      setRegisteredCompetitions(newComps);
    } catch (e) {}
  };

  return {
    user,
    registeredCompetitions,
    unregisteredCompetitions,
    logout,
    register,
    registerCompetition,
    addLink,
    loading,
  };
};