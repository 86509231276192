import styles from "../css/profile.module.css";
import { useAuth } from "../hooks";
import { useEffect } from "react";

const ProfilePage = () => {
  const auth = useAuth();

  useEffect(() => {
    const originalHtmlStyles = document.documentElement.style.cssText;
    const originalBodyStyles = document.body.style.cssText;

    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";
    document.documentElement.style.height = "100%";
    document.documentElement.style.overflow = "hidden";

    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.cssText = originalHtmlStyles;
      document.body.style.cssText = originalBodyStyles;
    };
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.profileTitleWrapper}>
        <div className={styles.decorativeImageLeft}></div>
        <div className={styles.profileTitle}>PROFILE</div>
        <div className={styles.decorativeImageRight}></div>
      </div>
      <div className={styles.profilePageWrapper}>
        <div className={styles.corner}></div>
        <div className={styles.borderTop}></div>
        <div className={styles.borderBottom}></div>
        <div className={styles.borderLeft}></div>
        <div className={styles.borderRight}></div>
        <div className={styles.profilePage}>
          <div className={styles.wrapper}>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Name</div>
                <div className={styles.value}>{auth.user.details.name}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Mobile Number</div>
                <div className={styles.value}>{auth.user.details.phone}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Email Id</div>
                <div className={styles.value}>{auth.user.details.email}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Whatsapp Number</div>
                <div className={styles.value}>{auth.user.details.whatsapp}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Postal Address</div>
                <div className={styles.value}>{auth.user.details.address}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>College</div>
                <div className={styles.value}>{auth.user.details.college}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>City of College</div>
                <div className={styles.value}>{auth.user.details.collegeCity}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Year of Study</div>
                <div className={styles.value}>{auth.user.details.year}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Team Name</div>
                <div className={styles.value}>{auth.user.details.teamName}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Antaragni Team Id</div>
                <div className={styles.value}>{auth.user.details.teamId}</div>
              </div>
            </div>
            <div className={styles.textInput}>
              <div className={styles.labelValueWrapper}>
                <div className={styles.label}>Antaragni Id</div>
                <div className={styles.value}>{auth.user.details.id}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
