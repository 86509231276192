import styles from "../css/navbar.module.css";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";
import { useState, useEffect } from "react";
function Navbar() {
  const auth = useAuth();
  const location = useLocation();
  return (
    <div className={styles.mainbar}>
      {/* <img className={styles.logo} src={logo} alt="AntaragniLogo" /> */}
      <div className={styles.links}>
        {!auth.user ? (
          location.pathname === "/login" ? 
            null
           : (
            <Link to="/login" className={styles.link}>
              Login
            </Link>
          )
        ) : (
          <>
            {location.pathname !== "/" ? (
              <Link to="/" className={styles.link}>
                Home
              </Link>
            ) : (
              <Link to="/dashboard" className={styles.link}>
                Dashboard
              </Link>
            )}

            <span
              onClick={() => {
                auth.logout();
              }}
              className={styles.link}
            >
              Logout
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default Navbar;
