import styles from "../css/register.module.css";
import btnStyles from "../css/button.module.css";
import { useAuth } from "../hooks";
import { useState, useEffect } from "react";
import Select from "react-select";
import colleges from "../data/colleges.json";
import { getAllDocs, setData, updateData } from "../config/firebaseFirestore";
import ShortUniqueId from "short-unique-id";
import { firebaseLinkPhone, firebaseVerifyOTP } from "../config/firebaseAuth";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { ShowToast } from "../components";
import Popup from "reactjs-popup";
import wingsl from "../assets/dashboard-components/wings-left.png";
import wingsr from "../assets/dashboard-components/wings-right.png";
import "reactjs-popup/dist/index.css";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const uid = new ShortUniqueId({
    length: 10,
    dictionary: "alphanum_upper",
  });
  const [collegeOptions, setCollegeOptions] = useState(colleges["data"]);
  const [name, setName] = useState(auth.user.user.displayName.toUpperCase());
  const [email, setEmail] = useState(auth.user.user.email);
  const [address, setAddress] = useState("");
  const [teamId, setTeamId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [college, setCollege] = useState("");
  const [collegeCity, setCollegeCity] = useState("");
  const [yos, setYos] = useState("");
  const [gender, setGender] = useState("");
  const [wa, setWa] = useState("");
  const [mobile, setMobile] = useState(
    auth.user.user.phoneNumber != null
      ? auth.user.user.phoneNumber.slice(3)
      : ""
  );
  const [fb, setfb] = useState("");
  const [insta, setInsta] = useState("");
  const [otherTeam, setOtherTeam] = useState(false);
  const [otherCollege, setOtherCollege] = useState(false);
  const [teams, setTeams] = useState([]);
  const [otp, setOtp] = useState("");
  // const [sendingOtp, setSendingOtp] = useState(false);
  // const [phoneVerified, setPhoneVerified] = useState(
  //   auth.user.user.phoneNumber !== null
  // );
  const [isTeamLeader, setIsTeamLeader] = useState("NO");
  const [clubName, setClubName] = useState("");
  const [clubEmail, setClubEmail] = useState("");
  const [allTeams, setAllTeams] = useState([]);
  // Register Function
  const register = async () => {
    try {
      if (mobile.length != 10 || wa.length != 10) {
        ShowToast("Please enter correct Mobile / Whatsapp number !");
        return;
      }

      if (
        name != "" &&
        mobile != null &&
        mobile != "" &&
        address != "" &&
        college != null &&
        college != "" &&
        collegeCity != "" &&
        yos != "" &&
        gender != "" &&
        wa != "" &&
        teamId != null &&
        teamId != "" &&
        teamName != null &&
        teamName != "" &&
        isTeamLeader != ""
      ) {
        let UserData = {
          id: `ANT.${uid()}`,
          name: name,
          email: email,
          phone: mobile,
          whatsapp: wa,
          gender: gender,
          address: address,
          teamId: teamId,
          teamName: teamName,
          college: college.toUpperCase(),
          collegeCity: collegeCity,
          year: yos,
          fb: fb,
          insta: insta,
          isTeamLeader: isTeamLeader,
          dateTime: Date.now().toString(),
        };

        if (otherTeam) {
          let teamData = {};
          if (isTeamLeader === "YES" && clubName != "" && clubEmail != "") {
            teamData = {
              teamName: teamName,
              teamId: teamId,
              college: college.toUpperCase(),
              collegeCity: collegeCity,
              clubName: clubName,
              clubEmail: clubEmail,
            };
            await setData("eventsTeams24", teamId, teamData);
            auth.register(UserData);
          } else if (isTeamLeader === "NO") {
            teamData = {
              teamName: teamName,
              teamId: teamId,
              college: college.toUpperCase(),
              collegeCity: collegeCity,
            };
            await setData("eventsTeams24", teamId, teamData);
            auth.register(UserData);
          } else {
            ShowToast("Please Fill the Club Details !");
          }
        } else if (
          isTeamLeader === "YES" &&
          clubName != "" &&
          clubEmail != ""
        ) {
          let teamData = {
            clubName: clubName,
            clubEmail: clubEmail,
          };
          updateData("eventsTeams24", teamId, teamData);
          auth.register(UserData);
        } else if (isTeamLeader === "NO") {
          auth.register(UserData);
        } else if (isTeamLeader === "YES") {
          ShowToast("Please Fill the Club Details !");
        }
      } else {
        ShowToast("Please Fill all the Required Fields !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const filterCollegeOption = (option, inputValue) => {
  //   if (option.label === "Other") {
  //     const result = collegeOptions.filter((opt) =>
  //       opt.label.toUpperCase().includes(inputValue.toUpperCase())
  //     );
  //     return !result.length;
  //   }

  //   return option.label.toUpperCase().includes(inputValue.toUpperCase());
  // };

  // const filterTeamOption = (option, inputValue) => {
  //   if (option.label === "Other") {
  //     const result = teams.filter((opt) =>
  //       opt.label.toUpperCase().includes(inputValue.toUpperCase())
  //     );
  //     return !result.length;
  //   }

  //   return option.label.toUpperCase().includes(inputValue.toUpperCase());
  // };

  const teamDropdown = (college) => {
    let teamOptions = [
      {
        label: "Other",
        value: -1,
      },
      {
        label: "SOLO",
        value: `TM.ANT.${uid()}`,
      },
    ];
    for (let i = 0; i < allTeams.length; i++) {
      let currTeam = allTeams[i].data;
      if (currTeam.college === college && currTeam.name !== "SOLO") {
        let teamOption = {
          label: currTeam.teamName.toUpperCase(),
          value: currTeam.teamId,
        };
        teamOptions.push(teamOption);
      }
    }
    setTeams(teamOptions);
  };

  const getTeams = async () => {
    try {
      let teams = await getAllDocs("eventsTeams24");
      setAllTeams(teams);
      let prevCollegeOptions = [...collegeOptions];
      for (let i = 0; i < teams.length; i++) {
        let currTeam = teams[i].data;
        if (
          prevCollegeOptions.find((el) => {
            return el.label.toUpperCase() === currTeam.college.toUpperCase();
          }) === undefined
        ) {
          let collegeOption = {
            label: currTeam.college.toUpperCase(),
            value: currTeam.college.toUpperCase(),
          };
          prevCollegeOptions.push(collegeOption);
        }
      }
      setCollegeOptions(prevCollegeOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  const contentStyle = {
    background: "rgba(255, 255, 255, 0.12)",
    borderRadius: "5px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(8px)",
  };

  return (
    
    // <div className={styles.register}>
    //     <div className={styles.buttons}>
    //   <button className={styles.button1} onClick={() => { navigate("/") }}>𝑯𝒐𝒎𝒆</button>
    //   <button className={styles.button1} onClick={() => { auth.logout();
    //   navigate("/") }}>𝑳𝒐𝒈𝒐𝒖𝒕</button>
    //  </div>
    <div className={styles.registerContainer}>
      <div className={styles.wingsContainer}>
        <img src={wingsl} className={styles.wings} alt="Left Wing" />
        <h1
          className={styles.registertitle}
        >
          REGISTER
        </h1>
        <img src={wingsr} className={styles.wings} alt="Right Wing" />
      </div>
      <div className={styles.cover}>
        <div className={styles.topBorder}></div>
        <div className={styles.bottomBorder}></div>
        <div className={styles.leftBorder}></div>
        <div className={styles.rightBorder}></div>
        <div className={styles.corner}></div>
        <div className={styles.wrapper}>
          <div className={styles.registertext}>
            Please enter your details to register yourself
          </div>
          <div className={styles.details}>
            <div className={styles.field}>
              <label className={styles.labeltext}>Name*</label>
              <input
                className={styles.inputbox}
                type="text"
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value.toUpperCase());
                }}
                placeholder="Enter Your Name"
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>Email Id*</label>
              <input
                className={styles.inputbox}
                type="email"
                readOnly
                required
                defaultValue={email}
                placeholder="Enter Your Email Id"
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>
                Mobile Number*{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    Please enter your 10 digit valid mobile number here
                  </div>
                </Popup>
              </label>
              <input
                className={styles.inputbox}
                placeholder="Enter Your 10 digit mobile number"
                type="number"
                required
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>
                Whatsapp Number*{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    Please enter your 10 digit valid whatsapp number here
                  </div>
                </Popup>
              </label>
              <input
                className={styles.inputbox}
                placeholder="Enter your 10 digit WhatsApp number"
                type="number"
                required
                value={wa}
                onChange={(e) => {
                  setWa(e.target.value);
                }}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>
                Postal Address*{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    Please enter your complete postal address here
                  </div>
                </Popup>
              </label>
              <input
                className={styles.inputbox}
                placeholder="Enter Your Postal Address"
                type="text"
                required
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value.toUpperCase());
                }}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>Gender*</label>
              <select
                className={styles.inputbox}
                placeholder="Gender"
                type="text"
                required
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value.toUpperCase());
                }}
              >
                <option value="" className={styles.option}>
                  Gender
                </option>
                <option value="MALE" className={styles.option}>
                  MALE
                </option>
                <option value="FEMALE" className={styles.option}>
                  FEMALE
                </option>
                <option value="OTHER" className={styles.option}>
                  OTHER
                </option>
              </select>
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>
                College*{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    Kindly search for your college within this list. If your
                    college is not included in the options, please choose 'Other'
                  </div>
                </Popup>
              </label>
              <Select
                className={styles.inputbox}
                isSearchable
                placeholder={"Search college"}
                onChange={(option) => {
                  if (option.value === -1) {
                    setOtherCollege(true);
                    setCollege("");
                    teamDropdown("");
                  } else {
                    setOtherCollege(false);
                    setCollege(option.value.toUpperCase());
                    teamDropdown(option.value.toUpperCase());
                  }
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    color: "white",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: "14px",
                    lineHeight: 1.5,
                    alignItems: "center",
                    ":hover": {
                      backgroundColor: "#610909",
                      color: "white",
                    },
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0px",
                    paddingBottom: "8px",
                  }),
                  input: (base) => ({
                    ...base,
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  control: (base) => ({
                    ...base,
                    backgroundColor: "transparent",
                    borderRadius: "5px",
                    boxShadow: "none", // Disable the blue border
                    display: "flex",
                    alignItems: "center",
                    transition: "background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease",
                    cursor: "pointer",
                    border: "none",
                  }),
                  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isDisabled
                      ? null
                      : isSelected
                        ? "#610909"
                        : isFocused
                          ? "#610909"
                          : "#fff",
                    color: isDisabled
                      ? "#ccc"
                      : isSelected || isFocused
                        ? "white"
                        : "#610909",
                    cursor: isDisabled ? "not-allowed" : "default",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    marginTop: "0px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    maxHeight: "200px", // Adjust the maximum height based on your content length
                    zIndex: 9999, // Ensure the dropdown is above other elements if necessary
                  }),
                  menuList: (base) => ({
                    ...base,
                    overflowY: "auto",
                    maxHeight: "250px", // Adjust based on content
                  }),
                }}
                options={collegeOptions}
              />
            </div>
            {otherCollege && (
              <div className={styles.field}>
                <label className={styles.labeltext}>
                  College Name*{" "}
                  <Popup
                    trigger={
                      <span>
                        <AiOutlineQuestionCircle />
                      </span>
                    }
                    closeOnDocumentClick
                    position="right center"
                    {...{ contentStyle }}
                  >
                    <div className={styles.toolTipContent}>
                      Please provide the name of your college here. If your
                      college is not included in the provided options
                    </div>
                  </Popup>
                </label>
                <input
                  className={styles.inputbox}
                  placeholder="Enter Your College Name"
                  type="text"
                  required
                  value={college}
                  onChange={(e) => {
                    setCollege(e.target.value.toUpperCase());
                    teamDropdown("");
                  }}
                />
              </div>
            )}

            <div className={styles.field}>
              <label className={styles.labeltext}>
                City of College*{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    Please input the name of the city where your college is
                    situated
                  </div>
                </Popup>
              </label>
              <input
                className={styles.inputbox}
                placeholder="Enter City of College*"
                type="text"
                required
                value={collegeCity}
                onChange={(e) => {
                  setCollegeCity(e.target.value.toUpperCase());
                }}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>Year of Study*</label>
              <select
                className={styles.inputbox}
                placeholder="Year of Study*"
                type="text"
                required
                value={yos}
                onChange={(e) => {
                  setYos(e.target.value);
                }}
              >
                <option value="" className={styles.option}>
                  Year of Study
                </option>
                <option value="FIRST YEAR" className={styles.option}>
                  FIRST YEAR
                </option>
                <option value="SECOND YEAR" className={styles.option}>
                  SECOND YEAR
                </option>
                <option value="THIRD YEAR" className={styles.option}>
                  THIRD YEAR
                </option>
                <option value="FOURTH YEAR" className={styles.option}>
                  FOURTH YEAR
                </option>
                <option value="5+ YEAR" className={styles.option}>
                  5+ YEAR
                </option>
                <option value="CLASS 6" className={styles.option}>
                  CLASS 6
                </option>
                <option value="CLASS 7" className={styles.option}>
                  CLASS 7
                </option>
                <option value="CLASS 8" className={styles.option}>
                  CLASS 8
                </option>
                <option value="CLASS 9" className={styles.option}>
                  CLASS 9
                </option>
                <option value="CLASS 10" className={styles.option}>
                  CLASS 10
                </option>
                <option value="CLASS 11" className={styles.option}>
                  CLASS 11
                </option>
                <option value="CLASS 12" className={styles.option}>
                  CLASS 12
                </option>
              </select>
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>Facebook Profile</label>
              <input
                className={styles.inputbox}
                placeholder="Link to Your Facebook Profile"
                type="url"
                value={fb}
                onChange={(e) => {
                  setfb(e.target.value);
                }}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>Instagram Profile</label>
              <input
                className={styles.inputbox}
                placeholder="Link to Your Instagram Profile"
                type="url"
                value={insta}
                onChange={(e) => {
                  setInsta(e.target.value);
                }}
              />
            </div>
            <div className={styles.field}>
              <label className={styles.labeltext}>
                Team*{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    Please search your team from the provided list. If your team
                    is not available in the options, please select "Other".
                    Alternatively, if you intend to participate individually, you
                    can choose the "SOLO" option from the list
                  </div>
                </Popup>
              </label>
              <Select
                className={styles.inputbox}
                isSearchable
                placeholder={"Search Team"}
                onChange={(option) => {
                  if (option.value === -1) {
                    setOtherTeam(true);
                    setTeamName("");
                    setTeamId("");
                  } else {
                    setOtherTeam(false);
                    setTeamId(option.value);
                    setTeamName(option.label.toUpperCase());
                  }
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    color: "white",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontSize: "14px",
                    lineHeight: 1.5,
                    alignItems: "center",
                    ":hover": {
                      backgroundColor: "#610909",
                      color: "white",
                    },
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: "0px",
                    paddingBottom: "8px",
                  }),
                  input: (base) => ({
                    ...base,
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  control: (base) => ({
                    ...base,
                    backgroundColor: "transparent",
                    borderRadius: "5px",
                    boxShadow: "none", // Disable the blue border
                    display: "flex",
                    alignItems: "center",
                    transition: "background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease",
                    cursor: "pointer",
                    border: "none",
                  }),
                  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
                    ...base,
                    backgroundColor: isDisabled
                      ? null
                      : isSelected
                        ? "#610909"
                        : isFocused
                          ? "#610909"
                          : "#fff",
                    color: isDisabled
                      ? "#ccc"
                      : isSelected || isFocused
                        ? "white"
                        : "#610909",
                    cursor: isDisabled ? "not-allowed" : "default",
                    fontSize: "14px",
                    fontFamily: "Inknut Antiqua, serif",
                    fontWeight: 400,
                    fontStyle: "normal",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    marginTop: "0px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    maxHeight: "200px", // Adjust the maximum height based on your content length
                    zIndex: 9999, // Ensure the dropdown is above other elements if necessary
                  }),
                  menuList: (base) => ({
                    ...base,
                    overflowY: "auto",
                    maxHeight: "100px", // Adjust based on content
                  }),
                }}
                options={teams}
              />
            </div>
            {otherTeam && (
              <div className={styles.field}>
                <label className={styles.labeltext}>
                  Team Name*
                  <Popup
                    trigger={
                      <span>
                        <AiOutlineQuestionCircle />
                      </span>
                    }
                    closeOnDocumentClick
                    position="right center"
                    {...{ contentStyle }}
                  >
                    <div className={styles.toolTipContent}>
                      Please type the name of your team if it is not present in
                      the options provided
                    </div>
                  </Popup>
                </label>
                <input
                  className={styles.inputbox}
                  placeholder="Enter Your Team Name"
                  type="text"
                  required
                  value={teamName}
                  onChange={(e) => {
                    if (
                      teams.find(
                        (team) => team.label === e.target.value.toUpperCase()
                      ) !== undefined
                    ) {
                      ShowToast(
                        `${e.target.value.toUpperCase()} already exists in options, Please search it in options or create your New Team with different name`,
                        3000
                      );
                    }
                    setTeamName(e.target.value.toUpperCase());
                    setTeamId(`TM.ANT.${uid()}`);
                  }}
                />
              </div>
            )}
            <div className={styles.field}>
              <label className={styles.labeltext}>
                Are you a Team Leader ?{" "}
                <Popup
                  trigger={
                    <span>
                      <AiOutlineQuestionCircle />
                    </span>
                  }
                  closeOnDocumentClick
                  position="right center"
                  {...{ contentStyle }}
                >
                  <div className={styles.toolTipContent}>
                    If you are leading your team, please select "YES", If you are
                    not the leader then select "NO" and If you are participating
                    individually ("SOLO") then choose "NO"
                  </div>
                </Popup>
              </label>
              <select
                className={styles.inputbox}
                placeholder="Are you a Team Leader ?"
                type="text"
                required
                value={isTeamLeader}
                onChange={(e) => {
                  setIsTeamLeader(e.target.value.toUpperCase());
                }}
              >
                <option value="" className={styles.option}>
                  Select
                </option>
                <option value="YES" className={styles.option}>
                  YES
                </option>
                <option value="NO" className={styles.option}>
                  NO
                </option>
              </select>
            </div>

            {isTeamLeader === "YES" && (
              <>
                <div className={styles.field}>
                  <label className={styles.labeltext}>Club Name*</label>
                  <input
                    className={styles.inputbox}
                    placeholder="Enter Your Club Name"
                    type="text"
                    required
                    value={clubName}
                    onChange={(e) => {
                      setClubName(e.target.value.toUpperCase());
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <label className={styles.labeltext}>Club Email*</label>
                  <input
                    className={styles.inputbox}
                    placeholder="Enter Your Club Email"
                    type="email"
                    required
                    value={clubEmail}
                    onChange={(e) => {
                      setClubEmail(e.target.value);
                    }}
                  />
                </div>
              </>
            )}

            {/* {!sendingOtp ? (
            <div className={styles.field}>
              <label className={styles.labeltext}>Mobile Number*</label>
              <span className={styles.pair}>
                <input
                  className={styles.inputbox}
                  placeholder="Enter Your 10 digit mobile number"
                  type="number"
                  required
                  readOnly={phoneVerified}
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
                {!phoneVerified && (
                  <button
                    id="otpBtn"
                    className={btnStyles.btn}
                    onClick={() => {
                      if (mobile.length === 10) {
                        setSendingOtp(true);
                        firebaseLinkPhone(`+91${mobile}`, "otpBtn");
                      } else {
                        ShowToast("Enter valid 10 digits Mobile Number !");
                      }
                    }}
                  >
                    Send OTP
                  </button>
                )}
              </span>
            </div>
          ) : (
            <div className={styles.field}>
              <label className={styles.labeltext}>OTP*</label>
              <span className={styles.pair}>
                <input
                  className={styles.inputbox}
                  placeholder="Enter 6 digit OTP"
                  type="number"
                  required
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
                <button
                  id="otpBtn"
                  className={btnStyles.btn}
                  onClick={async () => {
                    if (otp.length === 6) {
                      try {
                        let user = await firebaseVerifyOTP(otp);
                        if (
                          user.phoneNumber != null &&
                          user.phoneNumber != ""
                        ) {
                          setPhoneVerified(true);
                        }
                        setSendingOtp(false);
                      } catch (error) {
                        console.log(error);
                      }
                    } else {
                      ShowToast("Enter valid 6 digits OTP");
                    }
                  }}
                >
                  Verify OTP
                </button>
                <button
                  id="otpBtn"
                  className={btnStyles.btn}
                  onClick={async () => {
                    setSendingOtp(false);
                  }}
                >
                  Resend OTP
                </button>
              </span>
            </div>
          )} */}
          </div>
          <div className={styles.buttonContainer}>
            <button className={btnStyles.btn} onClick={register}>
              Submit
            </button>
          </div>
        </div>
      </div>
      </div>
      // {/* </div> */}

  );
};

export default Register;